import { UserProvider } from './context/UserContext';
import RoutesConfig from './routes';

const App = () => (
  <UserProvider>
    <RoutesConfig />
  </UserProvider>
);

export default App;

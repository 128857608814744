import { createContext, useState, useEffect, FC, ReactNode } from 'react';
import { loginUser } from '../services/api';

interface UserProviderProps {
    children: ReactNode;
}

interface User {
    id: number;
    userName: string;
    email: string;
    token: string;
    refreshToken: string;
    userGroupId: number;
    groupName: string;
}

interface UserContextProps {
    user: User | null;
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => void;
}

// 创建上下文
export const UserContext = createContext<UserContextProps>({
    user: null,
    login: async () => false,
    logout: () => { },
});

// 封装 localStorage 相关操作
const getUserFromStorage = (): User | null => {
    const storedData = localStorage.getItem('user');
    if (!storedData) return null;

    const parsedData = JSON.parse(storedData);

    const currentTime = new Date().getTime();
    const expirationTime = 6 * 60 * 60 * 1000; // 有效期为 6 小时

    // 检查是否过期
    if (currentTime - parsedData.timestamp > expirationTime) {
        removeUserFromStorage(); // 如果过期，清除数据
        return null;
    }

    return parsedData.user; // 未过期，返回用户数据
};

const saveUserToStorage = (user: User) => {
    const dataToStore = {
        user,
        timestamp: new Date().getTime(), // 存储当前时间戳
    };
    localStorage.setItem('user', JSON.stringify(dataToStore));
};

const removeUserFromStorage = () => {
    localStorage.removeItem('user');
};

export const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(getUserFromStorage());

    useEffect(() => {
        // 在组件挂载时检查 localStorage 中是否有用户数据
        if (user) {
            setUser(getUserFromStorage());
        }
    }, []);

    // 调用API进行登录验证，获取 token 和 refreshToken
    const login = async (email: string, password: string): Promise<boolean> => {
        try {
            const response = await loginUser(email, password);
            const { id, userName, token, refreshToken, userGroup: { name: groupName }, userGroupId } = response.data;

            const userData: User = {
                id,
                userName,
                email, // 登录时传递的 email
                token,
                refreshToken,
                userGroupId,
                groupName
            };
            setUser(userData);
            saveUserToStorage(userData); // 将用户数据保存到 localStorage，带上时间戳
            return true; // 登录成功
        } catch (error) {
            console.error('登录失败', error);
            return false; // 登录失败
        }
    };

    // 登出函数
    const logout = () => {
        setUser(null);
        removeUserFromStorage(); // 从 localStorage 中移除用户数据
    };

    return (
        <UserContext.Provider value={{ user, login, logout }}>
            {children}
        </UserContext.Provider>
    );
};

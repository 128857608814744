import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';

const Login: React.FC = () => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    // 页面加载时自动聚焦到邮箱输入框
    useEffect(() => {
        const emailInput = document.getElementById('email');
        if (emailInput) {
            emailInput.focus();
        }
    }, []);

    // 如果 userContext 未提供，显示错误信息
    if (!userContext) {
        return <div>The context was not provided correctly</div>;
    }

    const { login } = userContext;

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        try {
            const result = await login(email, password); // 登录函数调用需要等待

            if (result) {
                setError(null);
                setSuccessMessage('Login successful, redirecting...');

                // 延迟1秒后跳转
                setTimeout(() => {
                    navigate('/profile'); // 登录成功后跳转
                }, 1000);
            } else {
                setError('Login failed, please check your email or password.');
            }
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed, please check your email or password.');
        } finally {
            setLoading(false);
        }
    };

    // 实时验证邮箱格式
    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const isFormValid = () => {
        return email && password && validateEmail(email);
    };

    return (
        <section className={styles.login_section}>
            <div className={styles.login_container}>
                <h2>Login Account</h2>
                <form className={styles.login_form} onSubmit={handleSubmit}>
                    <div className={styles.form_group}>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            disabled={loading} // 登录时禁用输入框
                            className={validateEmail(email) ? styles.valid_input : styles.invalid_input} // 动态样式
                        />
                        {!validateEmail(email) && email && (
                            <p className={styles.error_message}>Please enter a valid email address</p>
                        )}
                    </div>
                    <div className={styles.form_group}>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            disabled={loading} // 登录时禁用输入框
                        />
                    </div>
                    {loading && (
                        <div className={styles.loading_spinner}> {/* 加载指示器 */}
                            <div className={styles.spinner}></div>
                            <p>Logging in...</p>
                        </div>
                    )}
                    {error && <p className={styles.error_message}>{error}</p>}
                    {successMessage && <p className={styles.success_message}>{successMessage}</p>}
                    <button
                        type="submit"
                        className={styles.btn_submit}
                        disabled={loading || !isFormValid()} // 表单无效或加载中时禁用按钮
                    >
                        Login
                    </button>
                    <p>Don't have an account? <a className={styles.register_link} href="register">Register</a></p>
                </form>
            </div>
        </section>
    );
};

export default Login;

import BaseHub from './BaseHub';

class HeaderMenuHub extends BaseHub {
    constructor() {
        super("/Hubs/HeaderMenu");
    }

    // 事件处理方法，覆盖基类中的方法
    public registerOnServerEvents() {
        this.connection.on("MenuUpdated", () => {
            console.log("菜单已更新");

            this.refreshMenu();
        });

        // 处理服务器发送的 'Connected' 事件
        this.connection.on("Connected", () => {
            console.log("连接成功事件已从服务器接收。");
        });
    }
    private refreshMenu() {
        console.log("刷新菜单");
        localStorage.removeItem('menus')
    }
}

export const headerMenuHub = new HeaderMenuHub();

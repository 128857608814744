import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getImageListData, getImageTypeById } from '../../services/api'; // 导入 API 调用函数
import styles from './ImageList.module.css';
import { Helmet } from 'react-helmet';

interface ImageItem {
    id: number;
    title: string;
    indexImg: string;
    description: string;
    viewCount: number;
    imgCount: number;
    isVip: boolean; // VIP标记
    vipLevel: number;
    qualityMark: boolean; // 精品标记
}

const ImageList: React.FC = () => {
    const { typecode, page } = useParams(); // 获取 URL 中的 typecode 和 page 参数
    const navigate = useNavigate();
    const [images, setImages] = useState<ImageItem[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(page ? parseInt(page, 10) : 1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true); // 新增加载状态
    const [typeName, setTypeName] = useState(''); // 类型名称
    const [retryCount, setRetryCount] = useState<number>(0); // 自动重试计数器
    const maxRetries = 3; // 最大重试次数

    // 获取图片数据的函数
    const fetchImages = useCallback(async () => {
        setLoading(true); // 开始加载
        try {
            const data = await getImageListData(typecode!, currentPage); // 调用 API 获取数据
            if (data && data.items) {
                setImages(data.items);
                setTotalPages(data.totalPages);
                setRetryCount(0); // 重置重试计数器
            }
        } catch (error) {
            console.error('Failed to fetch images:', error);
            // 自动重试逻辑
            if (retryCount < maxRetries) {
                setRetryCount((prevCount) => prevCount + 1);
                fetchImages(); // 递归重试
            } else {
                alert('Failed to load data, please try again later.');
            }
        } finally {
            setLoading(false); // 加载完成
        }
    }, [typecode, currentPage, retryCount]);

    // 获取类型名称的函数
    const fetchTypeName = useCallback(async () => {
        try {
            const data = await getImageTypeById(typecode!); // 调用 API 获取数据
            if (data && data.title) {
                setTypeName(data.title);
            }
        } catch (error) {
            console.error('Failed to fetch type name:', error);
        }
    }, [typecode]);

    // 当页面参数或 typecode 变化时重新获取数据
    useEffect(() => {
        fetchImages();
        fetchTypeName();
    }, [fetchImages, fetchTypeName]);

    // 处理上一页点击事件
    const handlePrevPage = useCallback(() => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
            navigate(`/imageList/${typecode}/${prevPage}`);
            window.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动到顶部
        }
    }, [currentPage, navigate, typecode]);

    // 处理下一页点击事件
    const handleNextPage = useCallback(() => {
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            navigate(`/imageList/${typecode}/${nextPage}`);
            window.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动到顶部
        }
    }, [currentPage, navigate, totalPages, typecode]);

    // 使用 useMemo 缓存 images，减少重复计算
    const imageElements = useMemo(() => {
        return images.map((image) => (
            <div key={image.id} className={styles.image_item}>
                {/* 添加 VIP 和 精品标签 */}
                <div className={styles.vip_quality}>
                    {image.qualityMark && <div className={styles.quality_mark}>Quality</div>}
                    {image.isVip && <div className={styles.vip_mark}>VIP</div>}
                </div>
                <a href={`/showImg/${image.id}`}>
                    <img
                        src={`${image.indexImg}`}
                        alt={image.title}
                        className={styles.image_thumbnail}
                    />
                </a>
                <div className={styles.image_info}>
                    <p className={styles.img_count}>Number of images: {image.imgCount}</p>
                    <p className={styles.view_count}>Views: {image.viewCount}</p>
                </div>
                <a className={styles.image_titleLink} href={`/showImg/${image.id}`}>
                    <p className={styles.image_title}>{image.title}</p>
                </a>
            </div>
        ));
    }, [images]);

    return (
        <>
            {/* 动态修改页面标题 */}
            <Helmet>
                <title>{typeName} - www.girl8.top</title>
            </Helmet>

            <section className={styles.image_list_section}>
                <h2>{typeName}</h2>
                <div id="image-list-grid" className={styles.image_list_grid}>
                    {loading ? (
                        <p>Loading images...</p>
                    ) : (
                        imageElements
                    )}
                </div>

                {/* 分页控制 */}
                <div className={styles.pagination}>
                    <button
                        id="prevPage"
                        className={styles.pagination_btn}
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        Previous page
                    </button>
                    <span id="pageInfo">
                        {currentPage} / {totalPages}
                    </span>
                    <button
                        id="nextPage"
                        className={styles.pagination_btn}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next page
                    </button>
                </div>
            </section>
        </>
    );
};

export default ImageList;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './VideoShowcase.module.css';
interface VideoShowcaseProps {
    title: string;
    typeid: number;
    videos: { id: number; isVip?: boolean; qualityMark?: boolean; title: string; indexImg: string; }[];
}


const VideoShowcase: React.FC<VideoShowcaseProps> = ({ title, videos, typeid }) => {
    const navigate = useNavigate();

    const handleVideoClick = (id: number) => {
        // 跳转到视频播放页面
        navigate(`/video/${id}`);
    };

    return (
        <section className={styles.video_showcase}>
            <h3>
                {typeid > 0 ? (<a href={`/videoList/${typeid}`}>{title}</a>) : (title)}
            </h3>
            <div className={styles.video_grid}>
                {videos.map((video) => (
                    <a href={`/showVideo/${video.id}`} key={video.id}>
                        <div className={styles.video_item}>
                            <img
                                src={video.indexImg}
                                alt={`Video ${video.id}`}
                                className={styles.video_thumbnail}
                                onClick={() => handleVideoClick(video.id)}
                                style={{ cursor: 'pointer' }}
                            />
                            <div className={styles.play_button}></div>
                            {video.isVip && <div className={styles.vip_tag}>VIP</div>}
                            {video.qualityMark && <div className={styles.premium_tag}>精品</div>}
                            <p className={styles.video_title}>{video.title}</p>
                        </div>
                    </a>
                ))}
            </div>
        </section>
    );
};


export default VideoShowcase;

import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';

const UserProfile: React.FC = () => {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    // 如果用户没有登录，显示提示信息
    if (!userContext || !userContext.user) {
        return <div style={{ color: 'white' }}>Not logged in. Please log in first.</div>;
    }

    const { user } = userContext;

    // 点击升级VIP按钮的事件
    const handleUpgradeVip = () => {
        // 处理升级VIP的逻辑
        navigate('/upgradeVip');
    };

    return (
        <div style={{ color: 'white', padding: '70px 20px 20px 20px' }}>
            {/* 用户信息展示 */}
            <h1>User Information</h1>
            <p>Username: {user.userName}</p>
            <p>User Group: {user.groupName}</p>

            {/* 如果用户不是VIP，显示升级按钮 */}
            {user.userGroupId == 1 && (
                <div style={{ marginTop: '20px' }}>
                    <button
                        onClick={handleUpgradeVip}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#28a745',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px'
                        }}
                    >
                        Upgrade to VIP
                    </button>
                </div>
            )}
        </div>
    );
};

export default UserProfile;

import React from 'react';
import styles from './Footer.module.css';

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear(); // 获取当前年份

    return (
        <footer className={styles.footer}>
            <p>© {currentYear}. <a href='https://www.girL8.top'>www.girL8.top</a></p>
        </footer>
    );
};

export default Footer;

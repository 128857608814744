import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getVideoListData, getVideoTypeById } from '../../services/api';
import styles from './VideoList.module.css';
import { Helmet } from 'react-helmet';

interface VideoItem {
    id: number;
    title: string;
    indexImg: string;
    description: string;
    viewCount: number;
    imgCount: number;
    playTime: number;
    isVip: boolean;
    vipLevel: number;
    qualityMark: boolean; // 精品标记
}

// 将秒数转换为分钟和秒的格式
const formatPlayTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} minutes ${remainingSeconds} seconds`;
};

const VideoList: React.FC = () => {
    const { typecode, page } = useParams(); // 从 URL 获取参数
    const navigate = useNavigate();
    const [videos, setVideos] = useState<VideoItem[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(page ? parseInt(page, 10) : 1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [typeName, setTypeName] = useState('');
    const [retryCount, setRetryCount] = useState<number>(0); // 自动重试计数器
    const maxRetries = 3; // 最大重试次数

    // 获取视频列表数据
    const fetchList = useCallback(async () => {
        setLoading(true); // 开始加载
        try {
            const data = await getVideoListData(typecode!, currentPage);
            if (data && data.items) {
                setVideos(data.items);
                setTotalPages(data.totalPages);
                setRetryCount(0); // 重置重试计数器
            }
        } catch (error) {
            console.error('Failed to fetch videos:', error);
            // 自动重试逻辑
            if (retryCount < maxRetries) {
                setRetryCount((prevCount) => prevCount + 1);
                fetchList(); // 重试
            } else {
                alert('Failed to load videos, please try again later.');
            }
        } finally {
            setLoading(false); // 加载完成
        }
    }, [typecode, currentPage, retryCount]);

    // 获取类型名称
    const fetchTypeName = useCallback(async () => {
        try {
            const data = await getVideoTypeById(typecode!);
            if (data && data.title) {
                setTypeName(data.title);
            }
        } catch (error) {
            console.error('Failed to fetch type name:', error);
        }
    }, [typecode]);

    useEffect(() => {
        fetchList();
        fetchTypeName();
    }, [fetchList, fetchTypeName]);

    // 处理上一页点击事件
    const handlePrevPage = useCallback(() => {
        if (currentPage > 1) {
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
            navigate(`/videoList/${typecode}/${prevPage}`);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [currentPage, navigate, typecode]);

    // 处理下一页点击事件
    const handleNextPage = useCallback(() => {
        if (currentPage < totalPages) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            navigate(`/videoList/${typecode}/${nextPage}`);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [currentPage, navigate, totalPages, typecode]);

    // 生成视频元素列表
    const videoElements = useMemo(() => {
        return videos.map((item) => (
            <div key={item.id} className={styles.image_item}>
                {/* 添加 VIP 和 精品标签 */}
                <div className={styles.vip_quality}>
                    {item.qualityMark && <div className={styles.quality_mark}>Quality</div>}
                    {item.isVip && <div className={styles.vip_mark}>VIP</div>}
                </div>

                <a href={`/showVideo/${item.id}`}>
                    {/* 播放按钮 */}
                    <div className={styles.play_button_container}>
                        <div className={styles.play_button}></div>
                    </div>

                    {/* 视频缩略图 */}
                    <img
                        src={`${item.indexImg}`}
                        alt={item.title}
                        className={styles.image_thumbnail}
                    />
                </a>

                <div className={styles.image_info}>
                    <p className={styles.img_count}>Duration: {formatPlayTime(item.playTime)}</p>
                    <p className={styles.view_count}>Views: {item.viewCount}</p>
                </div>

                <a className={styles.image_titleLink} href={`/showVideo/${item.id}`}>
                    <p className={styles.image_title}>{item.title}</p>
                </a>
            </div>
        ));
    }, [videos]);

    return (
        <>
            {/* 动态修改页面标题 */}
            <Helmet>
                <title>{typeName} - www.girl8.top</title>
            </Helmet>

            <section className={styles.image_list_section}>
                <h2>{typeName}</h2>
                <div id="image-list-grid" className={styles.image_list_grid}>
                    {loading ? (
                        <p>Loading video...</p>
                    ) : (
                        videoElements
                    )}
                </div>

                {/* 分页控制 */}
                <div className={styles.pagination}>
                    <button
                        id="prevPage"
                        className={styles.pagination_btn}
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                    >
                        Previous page
                    </button>
                    <span id="pageInfo">
                        {currentPage} / {totalPages}
                    </span>
                    <button
                        id="nextPage"
                        className={styles.pagination_btn}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next page
                    </button>
                </div>
            </section>
        </>
    );
};

export default VideoList;

// src/pages/UpgradeVip.tsx
import React, { useContext, useEffect, useState } from 'react';
import { getAllVipData, saveToken, SendSponsoredLinks } from '../../services/api';  // 引入获取用户组数据的函数
import styles from './UpgradeVip.module.css';  // 引入CSS模块化文件
import { UserContext } from '../../context/UserContext';  // 引入 UserContext
import { useNavigate } from 'react-router-dom';

interface UserGroup {
    name: string;
    description?: string;
    vipEnableDays: number;
    price: number;
    id: number;
}

const UpgradeVip: React.FC = () => {
    const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [upgrading, setUpgrading] = useState<boolean>(false); // 升级状态
    const [paymentMessage, setPaymentMessage] = useState<string | null>(null); // 支付结果提示
    const [isSuccess, setIsSuccess] = useState<boolean>(true); // 成功或失败标志
    const userContext = useContext(UserContext);
    const { user, logout } = userContext || {};  // 获取用户登录状态
    const navigate = useNavigate(); // 初始化 useNavigate
    useEffect(() => {
        // 从API获取用户组数据
        const fetchUserGroups = async () => {
            try {
                const data = await getAllVipData();  // 直接使用返回的数据
                await saveToken();
                setUserGroups(data);
                setLoading(false);
            } catch (err) {
                setError('Login timed out, please log in again..');//登录超时，请重新登录
                logout?.();
                setLoading(false);
            }
        };

        fetchUserGroups();
    }, []);

    // 处理升级按钮点击事件
    const handleUpgradeClick = async (groupName: string, id: number) => {
        if (!user) {
            // 如果用户未登录，提示用户登录
            navigate("/login")
        } else {
            setUpgrading(true);  // 设置升级状态
            setPaymentMessage(null); // 清空之前的消息
            try {
                const user = localStorage.getItem('user');
                if (user) {
                    const parsedUser = JSON.parse(user);
                    const redirectUrl = `https://www.goodlf.com/card-sell/detail/${parsedUser.user.id}/${id}`;
                    // 跳转到指定的 URL
                    window.location.href = redirectUrl;
                }
                // 调用 SendSponsoredLinks 并获取返回值
                // const result = await SendSponsoredLinks(id);
                // if (result.data === "SUCCESS") {
                //     setPaymentMessage(`Payment link for ${groupName} has been sent to your email. Please check your inbox.`);
                //     setIsSuccess(true); // 设置成功状态
                // } else {
                //     setPaymentMessage(`Failed to send payment link for ${groupName}. Please try again.`);
                //     setIsSuccess(false); // 设置失败状态
                // }
            } catch (err) {
                setPaymentMessage('发送支付链接失败，请稍后重试。');
                setIsSuccess(false); // 失败时设为 false
            } finally {
                setUpgrading(false);  // 重置升级状态
            }
        }
    };

    // 优化加载状态显示
    if (loading) {
        return (
            <div className={styles.loadingContainer}>
                <div className={styles.spinner}></div> {/* 自定义 CSS 动画 */}
                <p>Loading VIP Groups...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.errorContainer}>
                <p className={styles.errorMessage}>{error}</p>
                <button className={styles.retryButton} onClick={() => window.location.reload()}>Retry</button>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            <header className={styles.header}>Upgrade to VIP</header>
            <div className={styles.vipUpgradeCard}>
                <h1>Select Your VIP Plan</h1>
                {paymentMessage && (
                    <p className={isSuccess ? styles.paymentMessageSuccess : styles.paymentMessageError}>
                        {paymentMessage}
                    </p>
                )} {/* 根据成功或失败动态切换样式 */}
                <table className={styles.vipTable}>
                    <thead>
                        <tr>
                            <th>User Group Name</th>
                            <th>VIP Validity (Days)</th>
                            <th>Recharge Amount</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userGroups.map((group) => (
                            <React.Fragment key={group.id}>
                                <tr>
                                    <td>{group.name}</td>
                                    <td>{group.vipEnableDays} days</td>
                                    <td>{group.price} USD</td>
                                    <td>
                                        <button
                                            className={styles.upgradeButton}
                                            onClick={() => handleUpgradeClick(group.name, group.id)}
                                            disabled={upgrading} // 按钮禁用状态
                                        >
                                            {upgrading ? 'Processing...' : `Upgrade ${group.name}`}
                                        </button>
                                    </td>
                                </tr>
                                <tr className={styles.expandedRow}>
                                    <td colSpan={4}>
                                        <strong>Description:</strong> {group.description || 'No description available'}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UpgradeVip;

// /services/api.ts
import axios from 'axios';
import { ImageListResponse, MediaData, VideoListResponse, } from './IServiceData';

// 创建 axios 实例
const api = axios.create({
    timeout: 8000,
    baseURL: '',//http://localhost:803
    headers: {
        'Content-Type': 'application/json', // 指定请求的类型
    },
});

// 登录 API
export async function loginUser(email: string, password: string) {
    try {
        const response = await api.post('/api/UserInfo/LoginUser', {
            email,
            password,
        });
        return response.data; // 返回服务器响应的数据
    } catch (error) {
        // 处理错误信息
        console.error('登录失败:', error);
        throw error; // 抛出错误，便于调用方处理
    }
}
// 注册 API
// /services/api.ts
export async function registerUser(email: string, password: string, username: string, verificationCode: string): Promise<{ isSuccess: boolean, message: string }> {
    try {
        const response = await api.post('/api/UserInfo/RegisterUser', {
            email,
            password,
            userName: username,
            verificationCode
        });
        // 根据 isSuccess 字段判断是否注册成功
        if (response.status === 200 && response.data.isSuccess === true) {
            return { isSuccess: true, message: response.data.message || 'Registration successful' }; // 注册成功
        } else {
            return { isSuccess: false, message: response.data.message || 'Registration failed' }; // 注册失败，返回错误消息
        }
    } catch (error) {
        console.error('注册失败:', error);
        return { isSuccess: false, message: 'Registration failed, please try again later.' }; // 捕获异常时的注册失败  注册失败，请稍后重试
    }
}
// 获取菜单数据 API
export async function getMenus(): Promise<any[]> {
    try {
        const response = await api.get('/api/SysConfig/GetMenus'); // 假设 API 端点为 /api/Menu/GetMenus
        return response.data.data; // 返回菜单数据
    } catch (error) {
        console.error('获取菜单数据失败:', error);
        throw new Error('Unable to retrieve menu data, please try again later.');//无法获取菜单数据，请稍后重试
    }
}
// 获取图片列表页数据 API
export async function getImageListData(typeId: string, pageNumber: number): Promise<ImageListResponse> {
    try {
        const response = await api.post('/api/ImgTypeInfo/GetImagesByType', {
            typeId,
            pageNumber
        });
        return response.data.data; // 返回数据
    } catch (error) {
        console.error('获取图片列表数据失败:', error);
        throw new Error('Unable to retrieve image list data, please try again later.');//无法获取图片列表数据，请稍后重试
    }
}
// 获取视频列表页数据 API
export async function getVideoListData(typeId: string, pageNumber: number): Promise<VideoListResponse> {
    try {
        const response = await api.post('/api/VideoTypeInfo/GetImagesByType', {
            typeId,
            pageNumber
        });
        return response.data.data; // 返回数据
    } catch (error) {
        console.error('获取视频列表数据失败:', error);
        throw new Error('Unable to retrieve video list data, please try again later.');
    }
}

// 根据图片类型id获取图片类型数据
export async function getImageTypeById(typeId: string): Promise<any> {
    try {
        const response = await api.get('/api/ImgTypeInfo/GetImgTypeInfo/' + typeId);
        return response.data.data; // 返回数据
    } catch (error) {
        console.error('获取图片类型数据失败:', error);
        throw new Error('Unable to retrieve image type data, please try again later');
    }
}
// 根据图片类型id获取视频类型数据
export async function getVideoTypeById(typeId: string): Promise<any> {
    try {
        const response = await api.get('/api/VideoTypeInfo/GetVideoTypeInfo/' + typeId);
        return response.data.data; // 返回数据
    } catch (error) {
        console.error('获取图片类型数据失败:', error);
        throw new Error('Unable to retrieve video type data, please try again later');
    }
}
// 根据图片id获取图片数据
export async function getImgInfo(id: string): Promise<any> {
    try {
        // 从 localStorage 中获取用户信息，并解析为对象
        const user = localStorage.getItem('user');
        let headers = {};

        // 如果用户存在且有 token，设置 Authorization 头
        if (user) {
            const parsedUser = JSON.parse(user);
            if (parsedUser.user.token) {
                headers = {
                    Authorization: `Bearer ${parsedUser.user.token}` // 携带 token 进行认证
                };
            }
        }

        // 发送请求，带上可能的 headers（如果有 token 则包含 Authorization）
        const response = await api.post(
            '/api/ImgInfo/GetImgInfo',
            { id: id },
            { headers }
        );

        return response.data; // 返回数据
    } catch (error) {
        console.error('获取图片数据失败:', error);
        throw new Error('Unable to retrieve image data, please try again later.');
    }
}


// 根据图片id获取视频数据
export async function getVideoInfo(id: string): Promise<any> {
    try {
        // 从 localStorage 中获取用户信息，并解析为对象
        const user = localStorage.getItem('user');
        let headers = {};

        // 如果用户存在且有 token，设置 Authorization 头
        if (user) {
            const parsedUser = JSON.parse(user);
            if (parsedUser.user.token) {
                headers = {
                    Authorization: `Bearer ${parsedUser.user.token}` // 携带 token 进行认证
                };
            }
        }
        const response = await api.post('/api/VideoInfo/GetVideoInfo', {
            id: id
        }, { headers });
        return response.data; // 返回数据
    } catch (error) {
        console.error('获取视频数据失败:', error);
        throw new Error('Unable to retrieve video data, please try again later.' + error);//无法获取视频数据，请稍后重试
    }
}
// 根据图片id获取推荐图片数据
export async function fetchRecommendImages(ImageId: string): Promise<any> {
    try {
        const response = await api.post('/api/ImgInfo/FetchRecommendImages', {
            ImageId: ImageId,
            Count: 6
        });
        return response.data; // 返回数据
    } catch (error) {
        console.error('获取图片数据失败:', error);
        throw new Error('Unable to retrieve image data, please try again later.');//无法获取图片数据，请稍后重试
    }
}
// 根据视频id获取推荐视频数据
export async function fetchRecommendVideos(VideoId: string): Promise<any> {
    try {
        const response = await api.post('/api/VideoInfo/FetchRecommendVideos', {
            VideoId: VideoId,
            Count: 16
        });
        return response.data; // 返回数据
    } catch (error) {
        console.error('获取视频数据失败:', error);
        throw new Error('Unable to retrieve video data, please try again later.');//无法获取视频数据，请稍后重试
    }
}
// 获取用户组数据
export async function getAllVipData(): Promise<any[]> {
    try {
        const response = await api.get('/api/UserGroup/GetUserGroups');
        return response.data.data; // 返回菜单数据
    } catch (error) {
        console.error('获取用户组数据失败:', error);
        throw new Error('Unable to retrieve user group data, please try again later.');//无法获取用户组数据，请稍后重试
    }
}
//保存token
export async function saveToken() {
    // 从 localStorage 中获取用户信息，并解析为对象
    const user = localStorage.getItem('user');
    let headers = {};
    // 如果用户存在且有 token，设置 Authorization 头
    if (user) {
        const parsedUser = JSON.parse(user);
        if (parsedUser.user.token) {
            headers = {
                Authorization: `Bearer ${parsedUser.user.token}` // 携带 token 进行认证
            };
        }
    }
    const response = await api.post('/api/UserInfo/saveToken', {
    }, { headers });
}
// 获取首页数据
export async function GetHomeData(): Promise<MediaData[]> {
    try {
        const response = await api.post('/api/NavInfo/GetHomeData');
        console.log(response.data.data);
        return response.data.data; // 返回数据
    } catch (error) {
        console.error('获取首页数据失败:', error);
        throw new Error('Unable to retrieve homepage data, please try again later.');//无法获取首页数据，请稍后重试
    }
}

// 发送验证码 API
export const sendVerificationCodeAPI = async (email: string) => {
    try {
        const response = await api.post('/api/UserInfo/SendVerificationCode', { email });
        return response.data;
    } catch (error) {
        console.error('Error sending verification code:', error);
        return { isSuccess: false };
    }
};
//发送赞助连接
export const SendSponsoredLinks = async (groupId: number) => {
    try {
        // 从 localStorage 中获取用户信息，并解析为对象
        const user = localStorage.getItem('user');
        let headers = {};

        // 如果用户存在且有 token，设置 Authorization 头
        if (user) {
            const parsedUser = JSON.parse(user);
            if (parsedUser.user.token) {
                headers = {
                    Authorization: `Bearer ${parsedUser.user.token}` // 携带 token 进行认证
                };
            }


        }

        const response = await api.post('/api/UserInfo/SendSponsoredLinks', { groupId }, { headers });
        return response.data;
    } catch (error) {
        console.error('Error sending verification code:', error);
        return { isSuccess: false };
    }
};
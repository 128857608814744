// Contact.tsx
import React from 'react';

import styles from './Contact.module.css';
const Contact: React.FC = () => {
    return (
        // <section className={styles.contact} id="contact">
        //     <h2>Contact Us</h2>
        //     <p>If you have any questions or need assistance, please contact us through the following ways.</p>
        //     <ul className={styles.contact_info}>
        //         <li>email: yangqq753@gmail.com</li>
        //     </ul>
        // </section>
        <></>
    );
};

export default Contact;

import { HubConnection, HubConnectionBuilder, LogLevel, HubConnectionState, HttpTransportType } from "@microsoft/signalr";

class BaseHub {
    public connection: HubConnection;
    private baseUrl: string;
    private connectionStatus: string = 'Connecting...';

    // 声明 onStatusChange 作为一个可选回调
    public onStatusChange?: (status: string) => void;

    constructor(hubUrl: string, baseUrl: string = "") //http://localhost:803
    {
        this.baseUrl = baseUrl;
        this.connection = new HubConnectionBuilder()
            .withUrl(`${this.baseUrl}${hubUrl}`, {
                transport: HttpTransportType.WebSockets | HttpTransportType.ServerSentEvents | HttpTransportType.LongPolling
            })
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect([0, 2000, 10000]) // 自动重连机制
            .build();

        this.registerOnServerEvents(); // 注册服务器事件
        this.handleConnectionStatus(); // 处理连接状态
    }

    // 将该方法改为 public，使其可以从外部访问
    public registerOnServerEvents() {
        // 基类的事件处理逻辑（留给继承类或其他扩展）
    }

    // 开始连接
    public async startConnection() {
        if (this.connection.state === HubConnectionState.Disconnected) {
            try {
                console.log("Starting SignalR connection...");
                await this.connection.start(); // 启动连接
                console.log("SignalR Connection started successfully.");
                this.connectionStatus = 'Connected'; // 连接成功
                this.updateConnectionStatus(); // 更新前端状态
            } catch (err) {
                console.error("SignalR Connection Error: ", err);
                this.connectionStatus = 'Failed to connect'; // 连接失败
                this.updateConnectionStatus(); // 更新前端状态
                console.error("Full error details:", err);

                // 在连接失败后重试
                setTimeout(async () => {
                    console.log("Retrying connection...");
                    this.connectionStatus = 'Reconnecting...'; // 更新为重连中
                    this.updateConnectionStatus(); // 更新前端状态
                    await this.startConnection(); // 再次尝试连接
                }, 5000); // 5秒后重试
            }
        } else {
            console.log("Connection is already in progress or connected.");
        }
    }

    // 获取当前连接状态
    public getConnectionStatus(): string {
        return this.connectionStatus;
    }

    // 处理 SignalR 的连接状态
    private handleConnectionStatus() {
        // 处理重连中
        this.connection.onreconnecting((error) => {
            console.log("Reconnecting... Error details:", error);
            this.connectionStatus = 'Reconnecting...'; // 重连中
            this.updateConnectionStatus(); // 更新前端状态
        });

        // 处理重连成功
        this.connection.onreconnected((connectionId) => {
            console.log(`Reconnected successfully. Connection ID: ${connectionId}`);
            this.connectionStatus = 'Connected'; // 重连成功
            this.updateConnectionStatus(); // 更新前端状态
        });

        // 处理连接关闭
        this.connection.onclose((error) => {
            console.error("Connection closed. Error details:", error);
            this.connectionStatus = 'Disconnected. Trying to reconnect...'; // 连接关闭
            this.updateConnectionStatus(); // 更新前端状态

            // 自动尝试重新连接
            setTimeout(async () => {
                await this.startConnection(); // 尝试重新连接
            }, 5000); // 5秒后重试
        });
    }

    // 更新前端的连接状态
    private updateConnectionStatus() {
        if (this.onStatusChange) {
            this.onStatusChange(this.connectionStatus); // 如果回调存在，调用它
        }
    }
}

export default BaseHub;

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { getMenus } from '../../services/api';
import styles from './Header.module.css';
import { headerMenuHub } from '../../Hubs/HeaderMenuHub';

interface MenuItem {
    id: string;
    title: string;
    href?: string;
    items?: { id: number; label: string; href: string }[];
}

const MenuComponent = React.memo(({ menu, isOpen, onToggle }: { menu: MenuItem; isOpen: boolean; onToggle: (id: string) => void }) => (
    <li className={styles.nav_item}>
        {menu.items ? (
            <>
                <a
                    href="#"
                    className={styles.dropdown_toggle}
                    aria-expanded={isOpen}
                    aria-controls={menu.id}
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggle(menu.id);
                    }}
                >
                    {menu.title} ▼
                </a>
                <ul className={`${styles.dropdown_menu} ${isOpen ? styles.show : ''}`} id={menu.id}>
                    {menu.items.map((item) => (
                        <li key={item.id}>
                            <a href={item.href}>{item.label}</a>
                        </li>
                    ))}
                </ul>
            </>
        ) : (
            <a href={menu.href}>{menu.title}</a>
        )}
    </li>
));

const Headers = () => {
    const [connectionStatus, setConnectionStatus] = useState(headerMenuHub.getConnectionStatus());
    const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
    const [dropdownStates, setDropdownStates] = useState<Partial<Record<string, boolean>>>({});
    const [menus, setMenus] = useState<MenuItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const userContext = useContext(UserContext);
    const { user, logout } = userContext || {};
    const navigate = useNavigate();

    const MENU_EXPIRATION_TIME = 6 * 60 * 60 * 1000;

    const toggleNavMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setIsNavMenuOpen(!isNavMenuOpen);
        event.stopPropagation();
    }, [isNavMenuOpen]);

    const toggleDropdown = useCallback((menuId: string) => {
        setDropdownStates((prevState) => ({
            [menuId]: !prevState[menuId],
        }));
    }, []);

    useEffect(() => {
        const updateConnectionStatus = (status: string) => setConnectionStatus(status);

        headerMenuHub.onStatusChange = updateConnectionStatus;

        headerMenuHub.startConnection().then(() => {
            updateConnectionStatus(headerMenuHub.getConnectionStatus());
        }).catch(() => {
            updateConnectionStatus(headerMenuHub.getConnectionStatus());
        });

        return () => {
            headerMenuHub.connection.off('reconnected');
            headerMenuHub.connection.off('reconnecting');
            headerMenuHub.connection.off('close');
        };
    }, []);

    useEffect(() => {
        const fetchMenus = async () => {
            try {
                const data = await getMenus();
                const cacheData = {
                    menus: data,
                    timestamp: new Date().getTime(),
                };
                localStorage.setItem('menus', JSON.stringify(cacheData));
                setMenus(data);
                setLoading(false);
            } catch (err: any) {
                setError(err.message);
                setLoading(false);
            }
        };

        const storedData = localStorage.getItem('menus');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const currentTime = new Date().getTime();

            if (currentTime - parsedData.timestamp < MENU_EXPIRATION_TIME) {
                setMenus(parsedData.menus);
                setLoading(false);
            } else {
                fetchMenus();
            }
        } else {
            fetchMenus();
        }
    }, []);

    useEffect(() => {
        const handleDocumentClick = () => setDropdownStates({});

        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const handleLogout = useCallback(() => {
        logout?.();
        navigate('/');
    }, [logout, navigate]);

    if (loading) {
        return (
            <div className={styles.loadingWrapper}>
                <div className={styles.loadingText}>Loading...</div>
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.errorWrapper}>
                <p>Error: {error}</p>
                <button onClick={() => window.location.reload()}>重试</button>
            </div>
        );
    }

    return (
        <header className={styles.header} id="header">
            <div className={styles.logo}><a href='/'>GirL8</a></div>
            <button
                className={styles.menu_toggle}
                id="menuToggle"
                aria-expanded={isNavMenuOpen}
                aria-controls="navMenu"
                onClick={toggleNavMenu}
            >
                ☰
            </button>
            <nav
                className={`${styles.nav} ${isNavMenuOpen ? styles.active : ''}`}
                id="navMenu"
                onClick={(e) => e.stopPropagation()}
            >
                <ul className={styles.nav_list}>
                    {menus.map((menu) => (
                        <MenuComponent
                            key={menu.id}
                            menu={menu}
                            isOpen={!!dropdownStates[menu.id]}
                            onToggle={toggleDropdown}
                        />
                    ))}
                </ul>
            </nav>

            <div className={styles.user_info}>
                {user ? (
                    <>
                        <span className={styles.username}>
                            Welcome, <a className={styles.profile_link} href='/profile'>{user.userName}</a>
                        </span>
                        <button className={styles.logout_btn} onClick={handleLogout}>
                            Logout
                        </button>

                    </>
                ) : (
                    <>
                        <a href="/login" className={styles.login_link}>Login</a>
                        <a href="/register" className={styles.register_link}>Register</a>
                    </>
                )}
            </div>
        </header>
    );
};

export default Headers;

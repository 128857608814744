import React, { useState, useContext } from 'react';
import { registerUser, sendVerificationCodeAPI } from '../../services/api'; // 引入注册 API 和发送验证码 API
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext'; // 引入 UserContext
import styles from './RegUser.module.css';

const Register: React.FC = () => {
    const navigate = useNavigate();
    const { login } = useContext(UserContext)!; // 从 UserContext 中获取 login 方法
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        verificationCode: '' // 添加验证码字段
    });

    const [errors, setErrors] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        verificationCode: '' // 添加验证码错误
    });

    const [isCodeSent, setIsCodeSent] = useState(false); // 是否已发送验证码
    const [isLoading, setIsLoading] = useState(false); // 控制加载状态
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // 注册错误提示
    const [successMessage, setSuccessMessage] = useState<string | null>(null); // 注册成功提示

    // 处理表单输入变化
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        validateForm(e.target.name, e.target.value);
    };

    // 表单验证
    const validateForm = (name: string, value: string) => {
        let error = '';

        switch (name) {
            case 'username':
                if (value.trim().length < 3) {
                    error = 'Username must be at least 3 characters long';
                }
                break;
            case 'email':
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                    error = 'Please enter a valid email address';
                }
                break;
            case 'password':
                if (value.length < 6) {
                    error = 'Password must be at least 6 characters long';
                }
                break;
            case 'confirmPassword':
                if (value !== formData.password) {
                    error = 'Passwords do not match';
                }
                break;
            case 'verificationCode':
                if (value.trim().length !== 6) {
                    error = 'Verification code must be 6 digits';
                }
                break;
            default:
                break;
        }

        setErrors({
            ...errors,
            [name]: error
        });
    };

    // 处理发送验证码
    const sendVerificationCode = async () => {
        if (formData.email === '' || errors.email) {
            setErrorMessage('Please enter a valid email before requesting the verification code.');
            return;
        }

        setIsLoading(true);
        try {
            const response = await sendVerificationCodeAPI(formData.email); // 调用发送验证码的API
            if (response.isSuccess) {
                setIsCodeSent(true);
                setSuccessMessage('Verification code sent to your email.');
            } else {
                setErrorMessage('Failed to send verification code. Please try again.');
            }
        } catch (error) {
            setErrorMessage('Error sending verification code.');
        } finally {
            setIsLoading(false);
        }
    };

    // 处理表单提交
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        // 检查是否有验证错误
        if (Object.values(errors).some((error) => error !== '') ||
            Object.values(formData).some((value) => value === '')) {
            console.error('There are errors in the form');
            return;
        }

        setIsLoading(true);
        setErrorMessage(null);
        setSuccessMessage(null);

        try {
            // 调用注册 API 时需要附加验证码
            const response = await registerUser(formData.email, formData.password, formData.username, formData.verificationCode);

            if (response.isSuccess) {
                // 注册成功后自动登录
                const loginSuccess = await login(formData.email, formData.password);
                if (loginSuccess) {
                    setSuccessMessage('Registration successful, logging in...');
                    setTimeout(() => navigate('/profile'), 2000); // 自动跳转到个人资料页
                } else {
                    setErrorMessage('Registration successful, but auto login failed. Please log in manually.');
                }
            } else {
                setErrorMessage(response.message || 'Registration failed, please check your input.');
            }
        } catch (error) {
            setErrorMessage('Registration failed, please check your input.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className={styles.register_section}>
            <div className={styles.register_container}>
                <h2>Register Account</h2>
                <form className={styles.register_form} onSubmit={handleSubmit}>
                    <div className={styles.form_group}>
                        <label htmlFor="username">NickName</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder="Enter nickname"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                        {errors.username && <p className={styles.error_message}>{errors.username}</p>}
                    </div>
                    <div className={styles.form_group}>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        {errors.email && <p className={styles.error_message}>{errors.email}</p>}
                    </div>
                    <div className={styles.form_group}>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Enter password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        {errors.password && <p className={styles.error_message}>{errors.password}</p>}
                    </div>
                    <div className={styles.form_group}>
                        <label htmlFor="confirm-password">Confirm Password</label>
                        <input
                            type="password"
                            id="confirm-password"
                            name="confirmPassword"
                            placeholder="Confirm password"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                        {errors.confirmPassword && <p className={styles.error_message}>{errors.confirmPassword}</p>}
                    </div>
                    <div className={styles.form_group}>
                        <label htmlFor="verificationCode">Verification Code</label>
                        <div className={styles.flex_row}>
                            <input
                                type="text"
                                id="verificationCode"
                                name="verificationCode"
                                placeholder="Enter verification code"
                                value={formData.verificationCode}
                                onChange={handleChange}
                                required
                            />
                            <button
                                type="button"
                                className={styles.btn_send_code}
                                onClick={sendVerificationCode}
                                disabled={isCodeSent} // 如果已经发送了验证码，则禁用按钮
                            >
                                {isCodeSent ? 'Code Sent' : 'Send Code'}
                            </button>
                        </div>
                        {errors.verificationCode && <p className={styles.error_message}>{errors.verificationCode}</p>}
                    </div>
                    {errorMessage && <p className={styles.error_message}>{errorMessage}</p>}
                    {successMessage && <p className={styles.success_message}>{successMessage}</p>}
                    {isLoading ? (
                        <p>Submitting...</p>
                    ) : (
                        <button
                            type="submit"
                            className={styles.btn_submit}
                            disabled={Object.values(errors).some(error => error !== '') ||
                                Object.values(formData).some(value => value === '')} // 当有错误时禁用按钮
                        >
                            Register
                        </button>
                    )}
                    <p>Already have an account? <a className={styles.register_link} href="login">Login</a></p>
                </form>
            </div>
        </section>
    );
};

export default Register;

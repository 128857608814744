import React from 'react';
import styles from './VideoBanner.module.css';

const VideoBanner: React.FC = () => {
    return (
        <section className={styles.video_banner} id="home">
            <video autoPlay muted loop className={styles.video_background} poster="/fallback-image.jpg">
                <source src="/video.mp4" type="video/mp4" />
                您的浏览器不支持 HTML5 视频。
            </video>
            <div className={styles.video_overlay}>
                <h1>Welcome to the GirL8 website</h1>
                <p>We offer exquisite images and videos to provide you with the best visual experience.</p>
            </div>
        </section>
    );
};

export default VideoBanner;

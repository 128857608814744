import React, { useEffect, useState, Suspense } from 'react';
import { GetHomeData } from '../../services/api';
import VideoBanner from '../../components/VideoBanner/VideoBanner';

const Gallery = React.lazy(() => import('../../components/Gallery/Gallery'));
const VideoShowcase = React.lazy(() => import('../../components/VideoShowcase/VideoShowcase'));

interface MediaItem {
    indexImg: string;
    alt?: string;
    id: number;
    title: string;
    isVip: boolean;
    qualityMark: boolean;
}

interface MediaData {
    typeId: number;
    type: 'image' | 'video';
    title: string;
    index: number;
    list: MediaItem[];
}

const Home: React.FC = () => {
    const [mediaData, setMediaData] = useState<MediaData[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await GetHomeData();
                setMediaData(data);
            } catch (err) {
                setError('无法加载首页数据，请检查网络连接或稍后重试。');
                console.error('数据加载错误:', err);
            } finally {
                setLoading(false); // 数据加载完成
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div style={loadingStyle}> {/* 使用居中的样式 */}
                <div>正在加载中...</div> {/* 加载文字 */}
            </div>
        );
    }

    if (error) {
        return (
            <div style={loadingStyle}>
                <p>{error}</p>
                <button onClick={() => window.location.reload()}>重试</button> {/* 提供重试按钮 */}
            </div>
        );
    }

    return (
        <>
            <VideoBanner />
            <Suspense fallback={<div style={loadingStyle}>加载组件中...</div>}>
                {mediaData.map((section) => {
                    if (section.type === 'image') {
                        return <Gallery key={section.index} title={section.title} typeid={section.typeId} images={section.list} />;
                    } else if (section.type === 'video') {
                        return <VideoShowcase key={section.index} title={section.title} typeid={section.typeId} videos={section.list} />;
                    }
                    return null;
                })}
            </Suspense>
        </>
    );
};

// 居中样式
const loadingStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // 占满整个视窗高度
    textAlign: 'center',
};

export default Home;

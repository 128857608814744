import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './layouts/Layout';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Register from './pages/Register/RegUser';
import ImageList from './pages/ImgList/ImgList';
import ImageDetail from './pages/ImageDetail/ImageDetail';
import VideoShow from './pages/VideoShow/VideoShow';
import UserProfile from './pages/UserProfile/UserProfile';
import Test from './pages/Test/Index';
import VideoList from './pages/VideoList/Index';
import UpgradeVip from './pages/UpgradeVip/UpgradeVip';
import NotFound from './pages/NotFound';

const RoutesConfig = () => (
    <Router>
        <Routes>
            <Route path="/" element={<Layout />}>
                {/* 在 Layout 组件中嵌套的路由 */}
                {/* <Route index element={<Test />} /> */}
                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                {/* 设置 page 参数为可选 */}
                <Route path='imageList/:typecode/:page?' element={<ImageList />} />
                <Route path='videoList/:typecode/:page?' element={<VideoList />} />

                <Route path='showImg/:id' element={<ImageDetail />} />
                <Route path='showVideo/:id' element={<VideoShow />} />
                <Route path='profile' element={<UserProfile />} />
                <Route path='upgradeVip' element={<UpgradeVip />} />
                {/* 404 页面 */}
                <Route path="*" element={<NotFound />} />
                {/* 可以在这里添加更多页面 */}
            </Route>
        </Routes>

    </Router>
);

export default RoutesConfig;

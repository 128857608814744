import React from 'react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { Outlet } from 'react-router-dom';

import './Layout.css';

import Contact from '../components/Contact/Contact';

const Layout: React.FC = () => {
    return (
        <>
            <Header />
            {/* Outlet 用于渲染当前匹配的子路由页面 */}
            <Outlet />
            <Contact />
            <Footer />
        </>
    );
};

export default Layout;
